<template>
  <div class="mb-12">
    <SubHeader :type="$t('navbar.admin')" />
    <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
      <!-- Integrations in progress -->
      <Card class="border border-gray-400 mb-5">
        <template #header>
          <div class="bg-[#1F3121] px-4 py-2 rounded-t-md border-b border-gray-300">
            <div class="flex-center space-x-4">
              <div class="flex-center bg-white rounded-md shadow px-2">
                <Icon icon="tabler:arrow-autofit-right" class="text-gray-800 w-7 h-7" />
              </div>
              <div class="flex-1">
                <span class="text-white block text-xl font-semibold">{{ $t("adminHome.integrationsInProcess") }}</span>
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex flex-col w-full">
            <div class="grid grid-cols-1 gap-4">
              <TransitionFade>
                <TableSkeleton class="min-w-full" v-if="loadingJobs" />
                <table class="min-w-full rounded h-[159px]" v-else>
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.id") }}</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.projectService") }}</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.status") }}</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.message") }}</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.dateCurrentSync") }}</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.dateInitSync") }}</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.dateEndSync") }}</th>
                      <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 tracking-wider text-left">{{ $t("adminHome.created") }}</th>
                      <!-- <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 uppercase tracking-wider text-left"></th> -->
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-if="job_integrations.length == 0">
                      <td class="text-center py-4" colspan="100%">
                        <InlineMessage severity="info">{{ $t("adminHome.noJobs") }}</InlineMessage>
                      </td>
                    </tr>
                    <tr v-for="(job, jobIndex) in job_integrations" :key="jobIndex" class="transition min-w-full hover:bg-gray-100">
                      <td class="px-5">
                        <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-900"> #{{ job.id }} </span>
                      </td>
                      <td class="px-5">
                        <div class="text-sm text-gray-700 font-semibold inline-block break-all">{{ job.integration.project_connection.project.name }}/ {{ job.integration.service.name }} - {{ job.integration.service.channel.name }}</div>
                      </td>
                      <td class="px-5">
                        <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-200 text-gray-800 uppercase">{{ job.state }}</span>
                      </td>
                      <td class="px-5">
                        <div class="text-sm text-gray-700 font-semibold inline-block break-words">{{ job.message_state }}</div>
                      </td>
                      <td class="px-5">
                        <small class="text-xs text-left font-semibold text-gray-600">
                          {{ $moment(job.date_current_sync).format("YYYY-MM-DD HH:mm:ss") }}
                        </small>
                      </td>
                      <td class="px-5">
                        <small class="text-xs font-semibold text-gray-600">
                          {{ $moment(job.date_init_sync).format("YYYY-MM-DD HH:mm:ss") }}
                        </small>
                      </td>
                      <td class="px-5">
                        <small class="text-xs font-semibold text-gray-600">
                          {{ $moment(job.date_end_sync).format("YYYY-MM-DD HH:mm:ss") }}
                        </small>
                      </td>
                      <td class="px-5 text-left text-sm text-gray-500">
                        {{ $moment(job.created_at).fromNow(true) }}
                        <br />
                        <small class="text-xs font-semibold text-gray-600">
                          {{ $moment(job.created_at).format("YYYY-MM-DD HH:mm") }}
                        </small>
                      </td>
                      <td class="px-5 text-left text-sm text-gray-500">
                        <Spinner v-if="job.loading" />
                        <span v-else key="button" class="inline-block py-1 px-1 leading-4 text-center whitespace-nowrap align-baseline rounded text-white cursor-pointer bg-blue-500 hover:bg-blue-400" style="font-size: 80%" @click="refreshJob(job.id, jobIndex)" v-tooltip.top="'Recargar'"><i class="pi pi-refresh"></i></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TransitionFade>
            </div>
          </div>
        </template>
      </Card>

      <!-- Integrations with Errors -->
      <TransitionFade>
        <Card class="border border-gray-400" v-if="integrationErrors.length > 0">
          <template #header>
            <div class="bg-[#1F3121] px-4 py-2 rounded-t-md border-b border-gray-300">
              <div class="flex-center space-x-4">
                <div class="flex-center bg-white rounded-md shadow px-2">
                  <Icon icon="tabler:alert-octagon" class="text-gray-800 w-7 h-7" />
                </div>
                <div class="flex-1">
                  <span class="text-white block text-xl font-semibold">{{ $t("adminHome.integrationsWithErrors") }}</span>
                </div>
              </div>
            </div>
            <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
            </div>
          </template>
          <template #content>
            <div class="flex flex-col w-full">
              <div class="grid grid-cols-1 gap-4">
                <TableSkeleton class="min-w-full" v-if="loadingJobs" />
                <table class="min-w-full rounded" v-else>
                  <thead class="bg-gray-50 w-full">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.id") }}</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.status") }}</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.step") }}</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.project") }}</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 tracking-wider">{{ $t("adminHome.channel") }}</th>
                      <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 tracking-wider text-left">{{ $t("adminHome.integrations") }}</th>
                      <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 tracking-wider text-left">{{ $t("adminHome.lastErrorDate") }}</th>
                      <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 tracking-wider text-left">{{ $t("adminHome.created") }}</th>
                      <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 tracking-wider text-left">{{ $t("adminHome.updated") }}</th>
                      <!-- <th scope="col" class="px-6 py-3"></th> -->
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-500">
                    <tr v-for="(integration, integrationIndex) in integrationErrors" :key="integrationIndex" class="transition min-w-full hover:bg-gray-100">
                      <td class="px-5">
                        <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-900"> #{{ integration.id }} </span>
                      </td>
                      <td class="px-5">
                        <span class="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium text-red-800 uppercase">{{ $t("adminHome.active") }}</span>
                      </td>
                      <td class="px-5">
                        <span class="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-800 capitalize">{{ integration.state }}</span>
                      </td>
                      <td class="px-5">
                        <div class="text-sm text-gray-700 font-semibold inline-block">{{ integration.project.name }} ({{ integration.project.company }})</div>
                      </td>
                      <td class="px-5">
                        <div class="text-sm text-gray-700 inline-block">
                          <div class="inline-flex items-center rounded-full bg-gray-500 px-2.5 py-0.5 text-xs font-medium text-white">
                            {{ integration.channel.name }}
                          </div>
                        </div>
                      </td>
                      <td class="px-5 text-center">
                        <div class="rounded-full h-5 w-5 inline-block bg-red-600">
                          <div class="text-sm text-white">
                            {{ integration.integrations.filter((f) => f.is_error == true).length }}
                          </div>
                        </div>
                      </td>
                      <td class="px-5 text-left text-sm text-gray-500">
                        <div class="inline-block" v-if="integration.integrations.find((f) => f.is_error == 1).last_error_date">
                          {{ $moment(integration.integrations.find((f) => f.is_error == 1).last_error_date).fromNow(true) }}
                          <br />
                          <small class="text-xs font-semibold text-gray-600">
                            {{ $moment(integration.integrations.find((f) => f.is_error == 1).last_error_date).format("YYYY-MM-DD HH:mm") }}
                          </small>
                        </div>
                        <div class="inline-block" v-else>
                          <div class="text-sm text-gray-700 font-semibold inline-block">-</div>
                        </div>
                      </td>
                      <td class="px-5 text-left text-sm text-gray-500">
                        {{ $moment(integration.created_at).fromNow(true) }}
                        <br />
                        <small class="text-xs font-semibold text-gray-600">
                          {{ $moment(integration.created_at).format("YYYY-MM-DD HH:mm") }}
                        </small>
                      </td>
                      <td class="px-5 text-left text-sm text-gray-500">
                        {{ $moment(integration.updated_at).fromNow(true) }}
                        <br />
                        <small class="text-xs font-semibold text-gray-600">{{ $moment(integration.updated_at).format("YYYY-MM-DD HH:mm") }}</small>
                      </td>
                      <!-- <td></td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </Card>
      </TransitionFade>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader"
import Spinner from "@/components/UI/Spinner.vue"
import TransitionFade from "@/components/TransitionFade"
import TableSkeleton from "@/components/UI/TableSkeleton"

// Framework components
import Card from "primevue/card"
import { Icon } from "@iconify/vue"
import InlineMessage from "primevue/inlinemessage"

export default {
  name: "Administrator",
  data() {
    return {
      job_integrations: [],
      integrationErrors: [],
      loadingJobs: true,
      filterPending: 0
    }
  },
  components: {
    SubHeader,
    Spinner,
    TransitionFade,
    TableSkeleton,

    Card,
    Icon,
    InlineMessage
  },
  created() {
    this.getUser()
  },
  async mounted() {
    await this.getIntegrationWithErrors()
    await this.getJobIntegration()
  },
  methods: {
    getUser() {
      this.$store.dispatch("Auth/getUser")
    },
    async getIntegrationWithErrors() {
      let filter = {}

      if (this.filterKeyword) filter["filter[id|project.id|project.company|project.company|state][like]"] = `%${this.filterKeyword}%`

      filter.page = 1
      filter.limit = 100
      filter.include = "project,channel"
      filter.sort = "-created_at"
      filter.errors = 1
      filter["filter[is_active][is]"] = 1
      filter["filter[project.is_active][is]"] = 1

      await this.$store.dispatch("AdminIntegration/list", filter)
      let integrations = []
      this.$store.state.AdminIntegration.data.forEach((integration) => {
        integrations.push({
          ...integration,
          integration_error: integration.integrations.every((f) => f.is_error == false || f.is_active == false)
        })
      })
      this.integrationErrors = integrations.filter((f) => f.integration_error == false)
    },
    async getJobIntegration() {
      let filter = {}

      filter.page = 1
      filter.limit = 50
      filter.include = "integration.project"
      filter["filter[format][=]"] = "manual"
      filter["filter[created_at][>]"] = this.$moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
      if (this.filterPending) {
        filter["filter[state][!in]"] = "FINISHED,FAILURE"
      }
      filter.sort = "-created_at"
      filter.include = "integration.project_connection.project,integration.service.channel"
      await this.$store.dispatch("AdminJobIntegration/list", filter)

      let jobs = []
      this.$store.state.AdminJobIntegration.data.forEach((j) => {
        if (j.integration != undefined && j.integration != null) jobs.push({ ...j, loading: false })
      })

      this.job_integrations = jobs
      if(this.job_integrations == 0) {
        this.loadingJobs = false
      } else if (this.job_integrations.length) this.loadingJobs = false
    },
    async refreshJob(job_id, index) {
      this.job_integrations[index].loading = true
      let filter = {}
      filter.page = 1
      filter.limit = 50
      filter["filter[format][=]"] = "manual"
      filter["filter[created_at][>]"] = this.$moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
      if (this.filterPending) {
        filter["filter[state][!in]"] = "FINISHED,FAILURE"
      }
      filter.sort = "-created_at"
      filter.include = "integration.project_connection.project,integration.service.channel"
      await this.$store.dispatch("AdminJobIntegration/list", filter)

      this.job_integrations[index] = Object.assign(
        this.job_integrations[index],
        this.$store.state.JobIntegration.data.find((f) => f.id == job_id)
      )
      this.job_integrations[index].loading = false
    }
  }
}
</script>
